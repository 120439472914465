<template>
  <div class="course-leaders">
    <div class="course-leaders__wrapper">

      <!--   FILTERS   -->
      <b-row>
        <b-col cols="12">
          <filters
              :disabled="loading"
              show-course-filter
              @input="selectFilter"
              @select:filter="selectFilter"
              @apply="apply"
          />
        </b-col>
      </b-row>
      <!--             -->

      <!--   TABLE      -->
      <b-row>
        <b-col>
          <main-table
            :list="list"
            :fields="fields"
            :loading="loading"
            @click:update="updateTable"
          />
        </b-col>
      </b-row>
      <!--             -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { CLService } from '@/services/clreport.service'
import Filters from '@/components/filters/Filters.vue'
import mainTable from '@/components/tables/MainTable.vue'
import CourseLeadersModel from '@/models/courseLeaders-model'

import showingMixin from '@/mixins/showing.mixin'

import {
  BCard,
  BCardHeader,
  BCardTitle,
  BRow,
  BCol,
  BCardText,
  BSpinner
} from 'bootstrap-vue';

export default {
  name: 'CLReport',

  metaInfo: {
    title: 'Course Leaders Reports',
  },

  mixins: [ showingMixin ],

  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BRow,
    BCol,
    BCardText,
    BSpinner,
    Filters,
    mainTable
  },

  setup(props, ctx) {
    const svc = new CLService(ctx.root.$http);

    return {
      svc,
      fields: [
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          tooltip: 'Name of course leader'
        },
        {
          key: 'exam_failed',
          label: 'Failed students',
          sortable: true,
          tooltip: 'Number of students from the courses having this Course leader from section 5 (for the selected period)'
        },
        {
          key: 'unpaid_reapplied',
          label: 'Reapplied students',
          sortable: true,
          tooltip: 'Number of students from failed ones that reapplied before Customer Service Manager contacted the student (added a note for the student on Reapply task list)'
        },
        {
          key: 'paid_reapplied',
          label: 'Paid Reapplied',
          sortable: true,
          tooltip: 'Number of students paid and reaplied'
        },
         {
          key: 'paid_reapply_rate',
          label: 'Rate',
          sortable: true,
          tooltip: '% of students paid reapplied relatively failed students'
        },
        {
          key: 'paid_reapplied_after_48h',
          label: 'Paid Reapplied after 48',
          sortable: true,
          tooltip: 'Number of students paid and reaplied after 48h'
        },
        {
          key: 'paid_reapply_after_48h_rate',
          label: 'Rate after 48 hours',
          sortable: true,
          tooltip: `% of students paid reapplied after 48 hours relatively failed students`
        },

      ],
    }
  },

  data: () => ({
    loading: false,
    list: null,
    filters: {
      date_after: null,
      date_before: null,
      course_date_after: null,
      course_date_before: null
    }
  }),

  computed: {
    ...mapGetters({
      user: 'user/user'
    }),

    hasDateAfter() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.date_after);
    },

    hasDateBefore() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.date_before);
    },
    hasCourseDateAfter() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.course_date_after);
    },

    hasCourseDateBefore() {
      let _getCookies = this.$cookies.get('filters')
      return !!(_getCookies && _getCookies.course_date_after);
    }
  },

  mounted() {
    // this.preSelectDateFilters()
  },

  methods: {
    async preSelectDateFilters() {

      let _today = this.$moment().format('YYYY-MM-DD')
      let _getCookies = this.$cookies.get('filters')
      let params = {
        date_after: this.$route.query.date_after ? this.$route.query.date_after : null,
        date_before: this.$route.query.date_before ? this.$route.query.date_before : null,
        course_date_after: this.$route.query.date_before || this.$route.query.date_after
            ? null
            : this.hasCourseDateAfter ?  _getCookies.course_date_after : _today,
        course_date_before: this.$route.query.date_before || this.$route.query.date_after
            ? null
            : this.hasCourseDateBefore ?  _getCookies.course_date_before : _today,
      }

      // if (!this.hasDateAfter || !this.hasDateBefore) await this.selectFilter({
      //   date_after: _today,
      //   date_before: _today
      // })
      // else await this.selectFilter({ date_after: _getCookies.date_after, date_before: _getCookies.date_before })
      // if (!this.hasCourseDateAfter || !this.hasCourseDateBefore) await this.selectFilter({
      //   course_date_after: _today,
      //   course_date_before: _today
      // })
      // else await this.selectFilter({ date_after: _getCookies.course_date_after, date_before: _getCookies.course_date_before })
      await this._selectFilter(params)
      await this.apply()
    },

    async updateTable() {
      await this.fetchData()
    },

    async fetchData(query) {
      try {
        this.loading = true
        let res = await this.svc.getCourseLeadersReports({ ...this.$route.query, ...query })
        this.list = res.map(item => new CourseLeadersModel(item))
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    },

    async selectFilter(payload) {
      const filterName = Object.keys(payload)[0];
      if (['course_date_after', 'course_date_before'].includes(filterName)) {
        this.filters.date_before = null;
        this.filters.date_after = null;
      } else {
        this.filters.course_date_after = null;
        this.filters.course_date_before = null;
      }
      if (this.loading) return
      this.filters = { ...this.filters, ...payload }
      this.$cookies.set('filters', this.filters);
      await this.setQuery(this.filters)
    },

    async _selectFilter(payload) {
      if (this.loading) return
      this.filters = { ...this.filters, ...payload }
      this.$cookies.set('filters', this.filters);
      await this.setQuery(this.filters)
    },

    async apply() {
      await this.fetchData()
    },

    async setQuery(data) {
      const query = { ...this.$route.query, ...data };
      Object.keys(query).forEach(key => (query[key] === undefined || query[key] === null) && delete query[key]);
      await this.$router.push({ query }).catch(() => {});
    },
  },

  provide() {
    return {
      setQuery: this.setQuery
    }
  }
}
</script>
