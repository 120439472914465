import { ApiServiceAbstract } from "@/services/api.service.abstract"

const qs = require('qs')

export class CLService extends ApiServiceAbstract {
  constructor(http) {
    super(http);
  }

  getCourseLeadersReports = (query) => {
    return this.request(this.http.get, 'statistic/employee/course_leaders/', {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    });
  }
}
