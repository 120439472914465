export default class CourseLeadersModel {
  constructor(item = {}) {
    this.name = item.name;
    this.exam_failed = item.exam_failed || 0;
    this.unpaid_reapplied = item.unpaid_reapplied || 0;
    this.paid_reapplied = item.paid_reapplied || 0;
    this.paid_reapplied_after_48h = item.paid_reapplied_after_48h;
    this.paid_reapply_after_48h_rate =  getReapplyRate(item.paid_reapply_after_48h_rate);
    this.paid_reapply_rate = getReapplyRate(item.paid_reapply_rate);
  }
}

function getReapplyRate(item) {
  if (item) return `${item.toFixed(2)} %`
  else return '0 %'
}